<template>
  <div class="page-classroom-view">
    <div class="my-page">
      <div class="page-user-noticeview">
        <div class="my-page">
          <h2>{{ viewData.Title }}</h2>
          <div class="notice-des">
            <span>{{ viewData.RealName }}发布</span>
            <span class="time">
              <van-icon name="clock-o" />
              {{ viewData.CreateDate }}
            </span>
          </div>
          <div class="notice-content" v-html="viewData.Content"></div>
        </div>
      </div>
      <div
        class="notice-file"
        v-show="viewData.accessoryList && viewData.accessoryList.length > 0"
      >
        <span>附件下载</span>
        <div class="" v-for="(item, i) in viewData.accessoryList" :key="i">
          <p @click="fileReview(item)" style="display:inline-block">
            {{ item.name }}
          </p>
          <a @click="review(item)">下载</a>
        </div>
      </div>
      <div
        class="notice-resources"
        v-show="viewData.resources && viewData.resources.length > 0"
      >
        <span>相关信息</span>
        <div
          class=""
          v-for="(item, i) in viewData.resources"
          :key="i"
          @click="viewResources(item)"
        >
          {{ item.Title }}
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import {
  Icon,
  Button,
  Form,
  Field,
  Uploader,
  Popup,
  Picker,
  DatetimePicker,
  Dialog,
  Collapse,
  CollapseItem,
  Step,
  Steps,
  Tag,
  Toast
} from "vant";
export default {
  components: {
    [Icon.name]: Icon,
    [Form.name]: Form,
    [Field.name]: Field,
    [Uploader.name]: Uploader,
    [Button.name]: Button,
    [Popup.name]: Popup,
    [Picker.name]: Picker,
    [DatetimePicker.name]: DatetimePicker,
    [Dialog.Component.name]: Dialog.Component,
    [Toast.name]: Toast,
    [Collapse.name]: Collapse,
    [CollapseItem.name]: CollapseItem,
    [Step.name]: Step,
    [Steps.name]: Steps,
    [Tag.name]: Tag
  },
  data() {
    return {
      minDate: new Date(new Date().getFullYear(), new Date().getMonth(), 1),
      id: null,
      auditID: null,
      type: null,

      viewData: {
        AccessoryPostList: [],
        flowList: []
      },
      leaveTypeList: [],
      showPicker: false,
      showTime: false,
      showTime2: false,
      loading: false,
      dialogVisible: false,
      AuditDesc: "",
      activeNames: ["1"]
    };
  },
  mounted() {
    if (this.$route.query.id) {
      this.id = this.$route.query.id;
    }

    this.getDetail();
  },
  methods: {
    getDetail() {
      this.$axios
        .get("/api/NewsNotice/Get", {
          id: this.id
        })
        .then(res => {
          if (res.code === 200) {
            this.viewData = {
              ...res.data,
              AccessoryPostList: res.data.accessoryList,
              flowList: res.data.auditHistoryList
            };
          } else {
            this.$toast.fail(res.msg || "操作成功");
          }
        });
    },

    viewResources(file) {
      if (file.Url) {
        window.open(file.Url);
      }
    },

    onConfirm(value) {
      this.viewData.LeaveTypeName = value.EnumName;
      this.viewData.LeaveType = value.EnumValue;
      this.showPicker = false;
    },
    onConfirmTime(value) {
      this.viewData.BeginDate = new Date(value).format("yyyy-MM-dd hh:mm");
      this.showTime = false;
      // 计算总时长
      this.sumTime();
    },
    onConfirmTime2(value) {
      this.viewData.EndDate = new Date(value).format("yyyy-MM-dd hh:mm");
      this.showTime2 = false;
      // 计算总时长
      this.sumTime();
    },
    sumTime() {
      const startTime = this.viewData.BeginDate;
      const endTime = this.viewData.EndDate;
      if (startTime && endTime) {
        this.$set(
          this.viewData,
          "LeaveTimeCount",
          this.$moment(endTime)
            .diff(this.$moment(startTime), "hours", true)
            .toFixed(1)
        );
      }
    },
    beforeRead(file) {
      Toast.loading({
        message: "加载中...",
        duration: 0
      });
      let formData = new FormData();
      formData.append("LastFile", file);
      formData.append("FileName", file.name);
      this.$axios.upload("/api/File/UploadFile", formData).then(res => {
        Toast.clear();
        if (res.code === 200) {
          this.viewData.AccessoryPostList.push({
            name: file.name,
            osskey: res.data.osskey,
            uploadUrl: res.data.uploadUrl
          });
          return true;
        } else {
          this.$toast.fail(res.msg || "操作失败");
          return false;
        }
      });
    },
    review(file) {
      if (file.url) {
        window.open(file.url);
      }
    },
    fileReview(file) {
      if (file.url) {
        const fileExtension = file.url.substring(file.url.lastIndexOf(".") + 1);

        if (
          fileExtension == "xls" ||
          fileExtension == "xlsx" ||
          fileExtension == "doc" ||
          fileExtension == "docx" ||
          fileExtension == "pptx" ||
          fileExtension == "ppt"
        ) {
          window.location.href =
            "https://view.officeapps.live.com/op/view.aspx?src=" + file.url;
        } else {
          this.$router.push({
            path: "Review",
            query: {
              url: file.url
            }
          });
        }
      }
    },
    save() {
      let that = this;
      this.$axios.post("/api/WorkerLeave/Add", this.viewData).then(res => {
        if (res.code === 200) {
          this.$toast.success(res.msg || "操作成功");
          setTimeout(() => {
            that.$router.replace({
              path: "LeaveTeacher",
              query: {
                active: 0
              }
            });
          }, 1000);
        } else {
          this.$toast.fail(res.msg || "操作失败");
        }
      });
    },
    handlePass() {
      let api = null;
      debugger;
      if (this.type === "1") {
        api = "/api/NewsNotice/Audit";
      } else if (this.type === "2") {
        api = "/api/HealthKnowledge/audit-knowledge";
      } else if (this.type === "3") {
        api = "/api/CloudClassroom/cloudClassroom-audit";
      } else if (this.type === "4") {
        api = "/api/GroupIntroduction/Audit";
      } else {
        api = "/api/ParentSchool/parentSchool-audit";
      }
      let that = this;
      this.$axios
        .post(api, {
          AuditDesc: "通过",
          AuditID: parseInt(this.auditID),
          AuditState: 3
        })
        .then(res => {
          if (res.code === 200) {
            this.$toast.success(res.msg || "操作成功");
            setTimeout(() => {
              that.$router.replace("AuditSchedule");
            }, 1000);
          } else {
            this.$toast.fail(res.msg || "操作失败");
          }
        });
    },
    beforeClose(action, done) {
      debugger;
      let that = this;
      let api = null;
      if (this.type === "1") {
        api = "/api/NewsNotice/Audit";
      } else if (this.type === "2") {
        api = "/api/HealthKnowledge/audit-knowledge";
      } else if (this.type === "3") {
        api = "/api/CloudClassroom/cloudClassroom-audit";
      } else if (this.type === "4") {
        api = "/api/GroupIntroduction/Audit";
      } else {
        api = "/api/ParentSchool/parentSchool-audit";
      }
      if (action === "confirm") {
        if (!this.AuditDesc) {
          this.$toast.fail("请填写驳回原因！");
          done(false);
        } else {
          this.$axios
            .post(api, {
              AuditDesc: this.AuditDesc,
              AuditID: parseInt(this.auditID),
              AuditState: 4
            })
            .then(res => {
              if (res.code === 200) {
                this.$toast.success(res.msg || "操作成功");
                this.AuditDesc = "";
                done();
                setTimeout(() => {
                  that.$router.replace("AuditSchedule");
                }, 1000);
              } else {
                this.$toast.fail(res.msg || "操作失败");
              }
            });
        }
      } else {
        this.AuditDesc = "";
        done();
      }
    }
  }
};
</script>
<style lang="less" scoped>
@import "./index.less";
</style>
